import styled from 'styled-components';

import img from '../../images/404.jpg';
import { fontSize, colors, mixins } from '../../constants';

export const ErrorContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(125deg, #3e0c1c 0, #2d0f5e);
  font-weight: bold;
  ${mixins.flexCenter()}
  flex-direction: column;

  h2 {
    color: ${colors.white};
    text-transform: capitalize;
    font-size: 40px;
  }
`;

export const ErrorHeader = styled.h1`
  font-size: 240px;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
  letter-spacing: 15px;
  font-family: 'montserrat', sans-serif !important;
  background: url(${img}) center no-repeat;
  background-size: contain;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
`;

export const ErrorDesc = styled.p`
  font-size: ${fontSize.large};
  color: ${colors.white};
  margin: 20px 0 50px 0;
`;

export const ErrorButton = styled.a.attrs({})`
  text-decoration: none;
  background: linear-gradient(45deg, #2196f3 0, #009688);
  color: ${colors.white};
  padding: 12px 24px;
  font-weight: bold;
  font-size: ${fontSize.large};
  display: inline-block;
  border-radius: 25px;
  box-shadow: 1px 3px 1px rgba(255, 123, 123, 1);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;

  ::before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(
      center,
      ellipse,
      rgba(255, 123, 123, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(255, 123, 123, 0.35) 0%,
      linear-gradient(45deg, #2196f3 0, #009688) 80%
    );
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
  }

  &:hover,
  :focus,
  :active {
    color: ${colors.white};
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  &:hover::before,
  :focus::before,
  :active::before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
`;
