import React from 'react';

import ErrorPage from '../views/404/404component';
import SEO from '../components/seo';
import './index.css';

export default () => (
  <>
    <SEO title="Page Not Found" />
    <ErrorPage />
  </>
);
